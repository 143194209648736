<template>
  <ContentWrapper :fullHeight="true">
    <template v-slot:title>
      <div class="flex-between">
        <div>Game Walkthrough</div>
        <div class="btn-actions">
          <div class="cancel btn" v-if="currentMode == 1" @click="cancelEdit">Cancel</div>
          <div class="edit btn" v-if="userRole === 'admin'" @click="toggleEditMode">{{currentMode == 0 ? "Edit" : "Save"}}</div>
        </div>
      </div>
    </template>
    <template v-if="currentMode == 0">
      <Container>
        <div v-html="content"></div>
      </Container>
    </template>

    <template v-else>
      <Container>
        <editor
          :initial-value="content"
          :api-key="API"
          :init="myInit"
          v-model="content"
      />
      </Container>
    </template>
  </ContentWrapper>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { eventBus } from '@/main.js'; 

//API
import { staticPageShow, staticPageUpdate } from '@/services/adminAPI/staticPage';

export default {
  components:{
    editor: Editor
  },
  data(){
    return{
      currentMode: 0,
      content: '',
      tempContent: '',
      myInit: {
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help | image | media',
        images_upload_handler: function (blobInfo, success, failure,folderName) {
          success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
        },
        init_instance_callback: (editor) => {
          editor.on('Load', function(e) {
            eventBus.$emit('loader',false);
          });

          //Hack to disable loader on google chrome
          setTimeout(() => {
            eventBus.$emit('loader',false);  
          }, 3000,this);
        }
      },
      API: '',
      loader: null,
    }
  },
  methods:{
    toggleEditMode(){
      if(this.currentMode == 1){
        this.updatePagesData();
        this.currentMode = 0;
      }else{
        this.currentMode = 1
        eventBus.$emit('loader',true);
      }
    },
    async updatePagesData(){
      eventBus.$emit('loader',true);
      staticPageUpdate('walkthrough',this.content).then((staticPageUpdateAPI) => {
        this.content = staticPagesUpdateAPI.data.response.staticPage.value
        this.tempContent = this.content;
        eventBus.$emit('loader',false);
      }).catch((error) => {
        eventBus.$emit('loader',false);
      });
    },
    cancelEdit(){
      this.currentMode = 0;
      this.content = this.tempContent;
    },
    async getPagesData(){
      eventBus.$emit('loader',true);

      staticPageShow('walkthrough').then((getStaticPagesAPI) => {
        this.content = getStaticPagesAPI.data.response.staticPage.value
        this.tempContent = this.content;
        eventBus.$emit('loader',false);

      }).catch((error) => {
        eventBus.$emit('loader',false);
      });

    }
  },
  async created() {
    this.API = process.env.VUE_APP_TINY_MCE_API_KEY;
    this.getPagesData();
  }
}
</script>

<style lang="scss" scoped>
  p{
    color: var(--gray414241);
  }

  .btn-actions{
    display: flex;
    flex-wrap: wrap;

    .cancel{
      &:hover{
        background: rgba($color: #000000, $alpha: 0.1);
      }
    }

    .edit{
      background: white;
      color: $grayDark;
    }


    .btn{
      font-size: 15px;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border-radius: 999px;
      font-weight: normal;
      margin-left: auto;

      @media screen and (max-width:450px){
        font-size: 13px;
      }
    }
  }

</style>